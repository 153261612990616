// import * as React from 'react';

import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { alpha, Button, Grid, Input, InputAdornment, InputBase, InputLabel, MenuItem, Skeleton, styled, TableHead, TextField, Tooltip, Typography } from '@mui/material';
import { Delete, Edit, RemoveRedEye, Search, SearchSharp, ThumbUp } from '@mui/icons-material';
import { blue, green, grey, orange, red } from '@mui/material/colors';
import { getAllAccountOfficers, getAllAdministrators, getAllShareholders } from '../../service/userService';
import { deleteFile, getFileEntriesByFileIdStatus, getFileUploadsById } from '../../service/fileService';
import { useNavigate } from 'react-router-dom';
import { approveShareholderBulkTicket } from '../../service/ticketService';
import { useDispatch, useSelector } from 'react-redux';
import { openAlert, setAlertMessage, setAlertTitle } from '../../store/alertSlice';
import { FormControl } from 'react-bootstrap';
import AlertModal from '../alertModal';
import { ShareholderSelect } from '../forms/FormComponents';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange} = props;


  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton> */}
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton> */}
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function FlyTable({data, headers, header, actions, searchFields, backPagination,
  handleNextOrPreviousPage = () => {}}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pageData, setpageData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);  
  const alert = useSelector((state) => state.alert);
  const [searching, setSearching] = useState(false);
  const [advanceSearched, setAdvanceSearched] = useState(false);
  const [advanceSearchValue, setAdvanceSearchValue] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchField, setSearchField] = useState("");

  useEffect(() => {
    setLoading(true)
    const test = () => {
      console.log('pageData: ', pageData);
      
      setTableProperties()
      
      setLoading(false)}
      test()
    
  },[data])

  const setTableProperties = () => {
    setpageData(data)

  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    if (backPagination.records_displayed < backPagination.total_elements && backPagination.records_displayed/rowsPerPage <= newPage) {
      setLoading(true)
      handleNextOrPreviousPage({newPage:newPage,rowsPerPage:rowsPerPage});
      setLoading(false)
    }
    
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const viewDetails = (row) => {
    console.log('RowId: ', row);
    switch (header) {
        case 'Files':
            navigate(`/file-entries/${row.id}`)            
            break;
    
        case 'File Entries':
            navigate(`/file-entries/${row.id}/violations`)            
            break;
        
        default:
            break;
    }
  }
  const editEntry = (row) => {
    console.log('RowId: ', row);
    switch (props.header) {
        case 'Files':
            navigate(`/account-officer-details/${row.id}`)            
            break;
    
        default:
            break;
    }
  }

  const removeFromArray = (id) => {
    let index = data.map(x=> {
      return x.id
    }).indexOf(id)
    console.log('index: ', index);
    data.splice(index, 1)
  }

  const deleteEntry = (row) => {
    console.log('RowId: ', row);
    switch (header) {
        case 'Files':
          deleteFile(row.id.toUpperCase()).then((response) => {
            console.log('Delete file response: ', response);
            if (response.data.operation_code === 0) {
              removeFromArray(row.id)
              dispatch(setAlertTitle("Success"));
              dispatch(setAlertMessage(response.data.message));
            } else {
              dispatch(setAlertTitle("Error"));
              dispatch(setAlertMessage(response.data.operation_description));
            }
            dispatch(openAlert());
            navigate("/file-approve", {
              replace: false,
            });
          });               
            break;
    
        case 'File Entries':
            navigate(`/file-entries/${row.id}/violations`)            
            break;
        
        default:
            break;
    }
  }
  const approveRecord = (row) => {
    console.log('RowId: ', row);
    switch (header) {
        case 'Files':
            approveShareholderBulkTicket(row.id).then((response) => {
              console.log('Approve response: ', response);
              if (response.data.operation_code === 0) {
                dispatch(setAlertTitle("Success"));
                dispatch(setAlertMessage(response.data.operation_description));
              } else {
                dispatch(setAlertTitle("Error"));
                dispatch(setAlertMessage(response.data.operation_description));
              }
              dispatch(openAlert());
              navigate("/shareholders", {
                replace: false,
              });
            });    
            break;

        default:
            break;
    }
  }

  const searchCurrentResults = (e) => {
    let searchValue = e.target.value
    setAdvanceSearchValue(searchValue)
    setSearching(true)
    if (searchValue === '') {
      setSearching(false)      
    }
    setSearchResults(
      data.filter((element) => {
        const elm = JSON.stringify(element)
        return (elm.toLowerCase()).includes(searchValue.toLowerCase())
          }))
  }

  const makeAdvancedSearch = () => {
    console.log('SearchFields: ', searchFields);
    console.log('Advanced Search Value: ', advanceSearchValue);
    switch (header) {
        case 'Files':          
        getFileUploadsById(advanceSearchValue).then((response) => {
              console.log('Approve response: ', response);
              if (response.data.operation_code === 0) {
                dispatch(setAlertTitle("Success"));
                dispatch(setAlertMessage(response.data.operation_description));
              } else {
                dispatch(setAlertTitle("Error"));
                dispatch(setAlertMessage(response.data.operation_description));
              }
              dispatch(openAlert());
              // navigate("/shareholders", {
              //   replace: false,
              // });
            });    
            break;

        default:
            break;
    }
  }

  return (
    <>
    {alert.open === true ? (
      <AlertModal
        sx={{ margin: "20px", width: "50%", align: "right" }}
      ></AlertModal>
    ) : (
      <></>
    )}
    {loading ? <Skeleton      
      variant="rectangle"
      height={"80vh"}
      width={"80vw"}></Skeleton> :
    <Box>
    <Grid container>
      
    <Grid item xs={8} md={8}>
      {advanceSearched ? (
        <>
          <Typography variant="h1">
            {`${searchResults?.length}Search results`}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h1">
            {`${data?.length} of ${backPagination.total_elements} ${header}`}
          </Typography>
        </>
      )}
    </Grid>
    <Grid item xs={4} md={4}>
      <Box sx={{ display: 'flex', justifyContent: 'end'}}>
        <SearchSharp sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          id="search_field"
          label="Search"
          variant="standard"
          onChange={searchCurrentResults}
          sx={{flex:"1 1 auto", marginBottom: 2}}
        />
      </Box>
    </Grid>
    </Grid>
    

    {searching && searchResults.length === 0 &&  (
        <Grid container>
          <Grid item xs={6} md={4}>
            <Typography variant="h1">Advanced Search By</Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} sx={{ display: 'flex'}}>
                  <ShareholderSelect
                    required
                    name="fieldSelect"
                    id="field-select"
                    onChange={(event) => {
                      setSearchField(event.target.value);
                    }}
                    sx={{flex:"1 1 auto", height: "100%" }}
                  >
                    {searchFields?.map((field) => (
                      <MenuItem key={field} value={field}>
                        {field}
                      </MenuItem>
                    ))}
                  </ShareholderSelect>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box sx={{ display: 'flex'}}>
                  <TextField
                    disabled
                    id="search_field"
                    label="Advanced Search"
                    variant="outlined"
                    value={advanceSearchValue}
                    sx={{flex:"1 1 auto"}}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#AA0050",
                    margin: "2% 1% 2% 0",
                    width: "100%",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#B150C5",
                    },
                    fontSize: 14,
                  }}
                  onClick={makeAdvancedSearch}
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Typography>Search</Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    <TableContainer component={Paper}>
      {data?.length === 0 ?
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableBody>
              <Typography variant='h4'>{header}</Typography>
                <TableRow>
                    {headers.map((header) => (
                        <TableCell>{header}</TableCell>
                    ))}
                </TableRow>
                <TableRow>
                  {'No data available'}
                </TableRow>
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={headers.length} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={headers.length}
                count={0}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table> : 
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableBody>
            <Typography variant='h4'>{header}</Typography>
              <TableRow>
                  {headers.map((header) => (
                      <TableCell>{header}</TableCell>
                  ))}
              </TableRow>
            { searching ?
              (rowsPerPage > 0 
                ? searchResults?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : searchResults
              ).map((row) =>(
                    <TableRow key={row.id}>
                        {Object.values(row).map((field) => (
                            <TableCell component="th" scope="row" sx={ 
                              row.status === 'PENDING_VALIDATION' || row.status === 'UNDER_BULK_UPLOAD' || row.status === 'UNDER_PROCESSING' ? {color:  orange[600], fontSize: '120%', fontWeight: 'medium' } :
                              row.status === 'FAILED_VALIDATION' ? {color:  red[500], fontSize: '120%', fontWeight: 'medium' } :
                              row.status === 'SUCCESSFULLY_VALIDATED' || row.status === 'SUCCESSFULLY_LOADED' ? {color:  green[500], fontSize: '120%', fontWeight: 'medium' } : 
                              {color:  grey[500], fontSize: '120%', fontWeight: 'medium' }}>
                                {field.length > 15 ? `${field
                                    .toLowerCase()
                                    .substring(0, 15)}...` : field.toLowerCase()}
                            </TableCell>
                        ))}
                        <TableCell component="th" scope="row">
                            {actions && actions.map(action => (
                                <Tooltip title={action}>
                                    <span>
                                        {action === 'Approve' ? 
                                        <IconButton
                                        disabled= {row.status === 'SUCCESSFULLY_VALIDATED' ? false : true}
                                        sx={{ color: blue [500] }}
                                        onClick={()=>approveRecord(row)}
                                        >
                                        <ThumbUp fontSize="small" />
                                        </IconButton> : action === 'View' ? 
                                        <IconButton
                                        disabled= {row.status === 'FAILED_VALIDATION' || row.status === 'PARTIALLY_LOADED' ? false : true}
                                        sx={{ color: green [500] }}
                                        onClick={()=>viewDetails(row)}
                                        >
                                        <RemoveRedEye fontSize="small" />
                                        </IconButton> : action === 'Edit' ?
                                        <IconButton
                                        sx={{ color: orange[500] }}
                                        onClick={() => editEntry(row)}
                                        >
                                        <Edit fontSize="small" />
                                        </IconButton> :
                                        <IconButton
                                        disabled= {row.status === 'SUCCESSFULLY_VALIDATED' ||row.status === 'PENDING_VALIDATION' || row.status === 'UNDER_PROCESSING' || row.status === 'UNDER_BULK_UPLOAD' ? true : false}
                                        sx={{ color: red[500] }}
                                        onClick={() => deleteEntry(row)}
                                        >
                                        <Delete fontSize="small" />
                                        </IconButton>}
                                    
                                    </span>
                                </Tooltip>
                            ))}
                        </TableCell>
                    </TableRow>
              )) : 
              (rowsPerPage > 0
                ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : data
              ).map((row) => (
                <TableRow key={row.id}>
                    {Object.values(row).map((field) => (
                        <TableCell component="th" scope="row" sx={ 
                          row.status === 'PENDING_VALIDATION' || row.status === 'UNDER_BULK_UPLOAD' || row.status === 'UNDER_PROCESSING' ? {color:  orange[600], fontSize: '120%', fontWeight: 'medium' } :
                          row.status === 'FAILED_VALIDATION' ? {color:  red[500], fontSize: '120%', fontWeight: 'medium' } :
                          row.status === 'SUCCESSFULLY_VALIDATED' || row.status === 'SUCCESSFULLY_LOADED' ? {color:  green[500], fontSize: '120%', fontWeight: 'medium' } : 
                          {color:  grey[500], fontSize: '120%', fontWeight: 'medium' }}>
                            {field.length > 15 ? `${field?.toLowerCase()
                                .substring(0, 15)}...` : String(field)?.toLowerCase()}
                        </TableCell>
                    ))}
                    <TableCell component="th" scope="row">
                        {actions && actions.map(action => (
                            <Tooltip title={action}>
                                <span>
                                    {action === 'Approve' ? 
                                    <IconButton
                                    disabled= {row.status === 'SUCCESSFULLY_VALIDATED' ? false : true}
                                    sx={{ color: blue [500] }}
                                    onClick={()=>approveRecord(row)}
                                    >
                                    <ThumbUp fontSize="small" />
                                    </IconButton> : action === 'View' ? 
                                    <IconButton
                                    disabled= {row.status === 'FAILED_VALIDATION' || row.status === 'PARTIALLY_LOADED' ? false : true}
                                    sx={{ color: green [500] }}
                                    onClick={()=>viewDetails(row)}
                                    >
                                    <RemoveRedEye fontSize="small" />
                                    </IconButton> : action === 'Edit' ?
                                    <IconButton
                                    sx={{ color: orange[500] }}
                                    onClick={() => editEntry(row)}
                                    >
                                    <Edit fontSize="small" />
                                    </IconButton> :
                                    <IconButton
                                    disabled= {row.status === 'SUCCESSFULLY_VALIDATED' ||row.status === 'PENDING_VALIDATION' || row.status === 'UNDER_PROCESSING' || row.status === 'UNDER_BULK_UPLOAD' ? true : false}
                                    sx={{ color: red[500] }}
                                    onClick={() => deleteEntry(row)}
                                    >
                                    <Delete fontSize="small" />
                                    </IconButton>}
                                
                                </span>
                            </Tooltip>
                        ))}
                    </TableCell>
                </TableRow>
              ))
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={headers.length} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={headers.length}
                // count={data.length}
                count={backPagination.total_elements}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                // backendPaginationDetails = {paginationDetails}
                // elements = {paginationDetails.total_elements}
              />
            </TableRow>
          </TableFooter>
        </Table>}
    </TableContainer>
    </Box>}
    </>
  );
}
