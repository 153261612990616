import { testApi } from "../config/API";

export const getFileEntriesByFileId = async (props) => {
  return testApi.get(
    `/file-uploads/${props.fileId}/entries?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=entry_Id&status=FAILED_VALIDATION`
  );
};


export const getFileEntriesByFileIdStatus = async (props) => {
  return testApi.get(
    `/file-uploads/${props.fileId}/entries?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=entry_Id&status=${props.status}`
  );
};

export const getFileUploads = async (props) => {
  return testApi.get(
    `file-uploads?size=${props.size}&page_index=${props.pageIndex}&direction=asc&field_name=file_Id`
  );
};
export const getFileUploadsById = async (props) => {
  return testApi.get(
    `file-uploads/${props}`
  );
};

export const deleteFile = async (props) => {
  return testApi.delete(
    `file-uploads/${props}`
  );
};